.App {
    text-align: center;
    background-color: #ddd9fd;
    overflow-x: hidden;
}



.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
