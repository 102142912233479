.Roadmap {
    background-image: url("images/roadmap-bg.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: white;
}

.RoadmapContent {
    text-align: left;
    font-family: WorkSans;
    max-width: min(70vw, 1100px);
    padding-top: 60px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
}

.RoadmapContent h1 {
    margin-top: 0;
    font-family: Symtext;
    font-size: 40px;
    font-weight: normal;
}

.roadmapPng {
    width: 100%;
}